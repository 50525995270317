import { Routes, Route } from "react-router-dom";
import About from "./pages/about";
import BottleRecharge from "./pages/bottle-recharge";
import CircleRecharge from "./pages/circle-recharge";
import BottleHomepage from "./pages/bottle-homepage";
import BottleMessage from "./pages/bottle-message";
import IcpBeian from "./pages/icp-beian";
import MiniRedirect from "./pages/mini-redirect";
import BlindBox from "./pages/blind-box/index";
import './App.css';


function App() {
  return (
    <Routes>
      <Route path="/" element={<IcpBeian />} />
      <Route path="/h5" element={<About />} />
      <Route path="/h5/about" element={<About />} />
      <Route path="/h5/bottle-recharge" element={<BottleRecharge />} />
      <Route path="/h5/circle-recharge" element={<CircleRecharge />} />
      <Route path="/h5/bottle-homepage" element={<BottleHomepage />} />
      <Route path="/h5/bottle-message" element={<BottleMessage />} />
      <Route path="/h5/icp-beian" element={<IcpBeian />} />
      <Route path="/h5/mini-redirect" element={<MiniRedirect />} />
      <Route path="/h5/blind-box" element={<BlindBox />} />
      <Route path="/h5/blind-box/index" element={<BlindBox />} />
    </Routes>
  );
}

export default App;
